import React from 'react'

// Components
import Layout from '../../components/layout.js'
import SEO from '../../components/seo.js'

// SVGs

export default () => (
    <Layout className="project project__r4r">
        <SEO title="Rally for Restaurants" />
        <section className="hero position-relative">
            <img
                className="hero-img"
                src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/rally-for-restaurants/r4r-homepage.png"
                alt="R4R Hero"
            />
        </section>
        <section className="padding-y-10 bg-orange-9">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="side-by-side-divider">
                            <div className="left-content margin-bottom-3">
                                <h3 className="margin-bottom-3">
                                    I helped develop a website in a week that
                                    drove $5m in gift card sales and $85m in
                                    online ordering sales.
                                </h3>
                                <p>
                                    <a
                                        className="underline"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href="https://www.rallyforestaurants.com"
                                    >
                                        RallyForRestaurants.com
                                    </a>{' '}
                                    is a website that helps provide restaurants
                                    with additional sales to sustain reduced
                                    traffic as a result of COVID19 shelter in
                                    place laws. This web application pulls in
                                    data about restaurants and displays it in a
                                    searchable list, allowing users to find
                                    their favorite restaurant and purchase a
                                    gift card or order food online.
                                </p>
                            </div>
                            <div className="divider margin-x-10"></div>
                            <div className="right-content">
                                <div className="margin-bottom-3">
                                    <h4>Timeline</h4>
                                    <p>1 Week Build</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="">My Role</h4>
                                    <p>Front End Developer</p>
                                </div>
                                <div className="margin-bottom-3">
                                    <h4 className="margin-bottom-1">
                                        <b>Tools</b>
                                    </h4>
                                    <div className="tag-container">
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">HTML</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Javascript
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Webpack
                                            </div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">AWS</div>
                                        </div>
                                        <div className="tag margin-bottom-1 margin-right-1">
                                            <div className="content">
                                                Algolia
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <h4 className="">
                                        <b>Themes</b>
                                    </h4>
                                    <p>
                                        Crossfunctional Collaboration | Big Data
                                        | Search
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 bg-grey-1">
            <div className="container">
                <div className="row margin-bottom-5">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">The Problem</h2>
                        <p>
                            A sharp increase in coronavirus cases caused many
                            cities acrross the US to enforce Shelter in Place
                            laws to prevent the spread of COVID19. This was a
                            critical blow to restaurants because as a result
                            many people no longer could go out to eat and
                            instead remained indoors. To make matters worse many
                            restaurants only had traditional brick &amp; mortar
                            solutions and didn't have an online ordering website
                            or electronic gift cards. As a result many
                            resaurants that were surviving paycheck to paycheck
                            were at threat of closing their doors for good
                            because they did not have the savings to survive
                            even a couple weeks without business.
                        </p>
                    </div>
                </div>
                <div className="row margin-bottom-5">
                    <div className="col-md-6">
                        <h2 className="margin-bottom-3">The Solution</h2>
                        <p>
                            Create a website that allowed users to search their
                            favorite restaurant and purchase an electronic gift
                            card or order food online. In addition, spread
                            awareness about the restaurant industry trends at
                            the time and inform restaurant owners about how they
                            could apply for financial help from the government.
                        </p>
                    </div>
                    <div className="col-md-5 offset-md-1">
                        <h2 className="margin-bottom-3">Goals</h2>
                        <ul className="inline-padding-none">
                            <li className="margin-bottom-2">
                                <p>Deploy it ASAP</p>
                            </li>
                            <li className="margin-bottom-2">
                                <p>
                                    Ensure the application is efficient and fast
                                </p>
                            </li>
                            <li>
                                <p>
                                    Allow people not on the Toast Platform to
                                    opt into including their restaurant on the
                                    website
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="margin-bottom-3">My Role</h2>
                        <p>
                            I was one of the lead front-end engineers developing
                            the application from the ground up. I helped get the
                            initial build of the application production ready,
                            focusing on refining the search experience and
                            restaurant feed.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="padding-y-10 overflow-hidden">
            <div className="container">
                <div className="row row-padding">
                    <div className="col-md-12 margin-bottom-5">
                        <h2 className="">Development Process</h2>
                    </div>

                    <div className="col-md-12 margin-bottom-5">
                        <h3 className="margin-bottom-3">
                            01. - Understanding the Available Data &amp;
                            Defining the Scope
                        </h3>
                        <p className="">
                            It was clear that primary objective of the team was
                            to deploy a website that allows users to buy
                            electronic giftcards as soon as possible. It was
                            also necessary to have the ability for restaurants
                            not on the Toast platform to opt into being listed
                            on the Rally for Restaurants website. Furthermore,
                            we needed to deploy this website in 4 days. With
                            these 3 pieces of criteria in mind we decided that a
                            static site hosted on AWS would be the most
                            efficient approach. This allowed for a fast
                            deployment, auto scaling to handle increased
                            traffic, and allowed the team to focus on the user
                            experience rather than getting bogged down with
                            system architecture.
                        </p>
                    </div>
                </div>
                <div className="row row-padding">
                    <div className="col-lg-5 offset-lg-2 order-lg-1 static-height position-relative margin-bottom-5">
                        <img
                            className="img-right-side border-full-2"
                            src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/rally-for-restaurants/Tatte-Bakery-Online-Ordering.jpg"
                            alt="Tatte Boston Search"
                        />
                    </div>
                    <div className="col-lg-5 order-lg-0 margin-bottom-5 align-self-center">
                        <h3 className="margin-bottom-3">
                            02. - Hook Up the Data
                        </h3>
                        <p className="">
                            Our team decided to use Algolia as our primary
                            method for searching for restaurants and to retrieve
                            data. Algolia provided an easy to use API to hook
                            into their indices making search as easy as a simple
                            function call. Furthmore, Algolia allowed us to sync
                            our data periodically to update their restaurant
                            feed with batch uploads meaning we could routinely
                            update our list with new restaurants that opted into
                            being listed on the wesbite.
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-5 static-height position-relative margin-bottom-5">
                        <img
                            className="img-left-side border-full-2"
                            src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/rally-for-restaurants/Tatte-Bakery-Boston.jpg"
                            alt="Tatte Boston Search"
                        />
                    </div>
                    <div className="col-lg-5 offset-lg-2 align-self-center margin-bottom-5">
                        <h3 className="margin-bottom-3">
                            03. - Iterating Over the Design
                        </h3>
                        <p className="margin-bottom-5">
                            With the strategy and tools decided the only thing
                            left to do was develop and deploy the site! Our team
                            worked closesly with the marketing design team to
                            build the initial designs and quickly iterate to
                            it's current design. At many times we were
                            developing the designed site while the designs
                            themselves were changing on the fly. Once completed
                            and reviewed we launched and within the first 24
                            hours we generated over one million in E-Giftcard
                            sales!
                        </p>

                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.rallyforrestaurants.com"
                            className="primary-button primary-button__red hover"
                        >
                            Check it Out!
                        </a>
                    </div>
                </div>

                <div className="row row-padding">
                    <div className="col-lg-12 position-relative margin-bottom-5">
                        <img
                            className="img-shadow-right border-full-2"
                            src="https://d2jotzgct8q460.cloudfront.net/imgs/projects/rally-for-restaurants/r4r-homepage.png"
                            alt="Homepage"
                        />
                    </div>
                </div>
            </div>
        </section>
    </Layout>
)
